import {Component} from '@angular/core';
import {GedBaseComponent, IGedBaseComponent} from '../../../../../../core/base/components/ged-base/ged-base.component';
import {BuildingModel} from '../../../../../../core/models/building.model';
import {FeiColumn} from '../../../../../../core/base/interfaces/fei-column.interface';
import {AuthService} from '../../../../../../core/services/auth.service';
import {GedService} from '../../../../admin/playground/docuware-playground/ged.service';
import {MatDialog} from '@angular/material/dialog';
import {FileSaverService} from 'ngx-filesaver';
import {GED_CONFIG} from '../../../../../../core/enums/ged-type.enum';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BuildingService} from '../../building.service';
import {ENUM_PERMISSIONS} from "../../../../../../core/enums/permission.enum";

@Component({
  selector: 'vex-building-details-document',
  templateUrl: './building-details-document.component.html',
  styleUrls: ['./building-details-document.component.scss']
})
export class BuildingDetailsDocumentComponent extends GedBaseComponent<BuildingModel> implements IGedBaseComponent {

  documentTypes = ['Plan', 'Photo', 'Autres'];
  feisColumns: Array<FeiColumn> = [
    { label: 'Nom du fichier', column: 'NOM_DU_DOCUMENT' },
    { label: 'Type de document', column: 'DOCUMENT_TYPE' },
    { label: 'Nombre de pages', column: 'DWPAGECOUNT'},
    { label: 'Date du dépôt', column: 'DATE_DU_DEPOT', type: 'date'},
    { label: '', column: 'actions',
      canUpdate: false,
      canDelete : this.authService.hasPermission(ENUM_PERMISSIONS.DELETE_BUILDING_DOCUMENT),
      canDownload: this.authService.hasPermission(ENUM_PERMISSIONS.DOWNLOAD_BUILDING_DOCUMENT)
    }
  ];
  displayedColumns = [];
  ENUM_PERMISSION = ENUM_PERMISSIONS;

  constructor(public service: BuildingService,
              private authService: AuthService,
              private gedService: GedService,
              public dialog: MatDialog,
              public fileSaver: FileSaverService ) {
    super(GED_CONFIG.building, gedService, service, dialog, fileSaver);
    this.displayedColumns = this.feisColumns.map(x => x.column);
  }

  initForm() {
    this.form = new FormGroup({
      documentType: new FormControl(null, [Validators.required]),
      predikId: new FormControl(this.id, [Validators.required]),
    });
    this.loading = false;
  }
}
