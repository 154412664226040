import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {PopoverService} from '../../../components/popover/popover.service';
import {ToolbarUserDropdownComponent} from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import icPerson from '@iconify/icons-ic/twotone-person';
import {AuthService} from '../../../../app/core/services/auth.service';
import {Observable} from 'rxjs';
import {UserProfileModel} from '../../../../app/core/models/user-profile.model';
import {UserProfileService} from '../../../../app/pages/application/management/user-profile/user-profile.service';
import {ConfigService} from "../../../services/config.service";
import {ApplicationEnvironmentEnum} from "../../../../app/core/enums/application-environment.enum";

@Component({
    selector: 'vex-toolbar-user',
    templateUrl: './toolbar-user.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {

    dropdownOpen: boolean;
    icPerson = icPerson;
    user$ = new Observable<UserProfileModel>();
    userContactType$ = new Observable<UserProfileModel>();
    isContactTypePresent = false;
    isProductionEnvironment = false;

    constructor(private popover: PopoverService,
                private authService: AuthService,
                private cd: ChangeDetectorRef,
                private userProfileService: UserProfileService,
                private configService: ConfigService,
    ) {
    }

    ngOnInit() {

        this.user$ = this.authService.currentUserValue$();
        this.user$.subscribe(user => {
            this.isContactTypePresent = user.contactType?.length > 0;
        });

        this.userContactType$ = this.userProfileService.entity$;
        this.userContactType$.subscribe(user => {
            this.isContactTypePresent = user.contactType?.length > 0;
            this.cd.detectChanges();
        });

        if (this.configService.env.applicationEnvironment === ApplicationEnvironmentEnum.PRODUCTION) {
            this.isProductionEnvironment = true;
        }

    }

    showPopover(originRef: HTMLElement) {
        this.dropdownOpen = true;
        this.cd.markForCheck();

        const popoverRef = this.popover.open({
            content: ToolbarUserDropdownComponent,
            origin: originRef,
            offsetY: 12,
            position: [
                {
                    originX: 'center',
                    originY: 'top',
                    overlayX: 'center',
                    overlayY: 'bottom'
                },
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'top',
                },
            ]
        });

        popoverRef.afterClosed$.subscribe(() => {
            this.dropdownOpen = false;
            this.cd.markForCheck();
        });
    }
}
