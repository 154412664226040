import {Component} from '@angular/core';
import {GedBaseComponent, IGedBaseComponent} from '../../../../../../core/base/components/ged-base/ged-base.component';
import {FeiColumn} from '../../../../../../core/base/interfaces/fei-column.interface';
import {AuthService} from '../../../../../../core/services/auth.service';
import {GedService} from '../../../../admin/playground/docuware-playground/ged.service';
import {MatDialog} from '@angular/material/dialog';
import {FileSaverService} from 'ngx-filesaver';
import {GED_CONFIG} from '../../../../../../core/enums/ged-type.enum';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SupplierService} from '../../supplier.service';
import {SupplierModel} from '../../../../../../core/models/supplier.model';
import {ENUM_PERMISSIONS} from "../../../../../../core/enums/permission.enum";

@Component({
  selector: 'vex-supplier-details-documents',
  templateUrl: './supplier-details-documents.component.html',
  styleUrls: ['./supplier-details-documents.component.scss']
})
export class SupplierDetailsDocumentsComponent extends GedBaseComponent<SupplierModel> implements IGedBaseComponent {

  documentTypes = ['KBIS', 'RIB', 'Autres'];
  feisColumns: Array<FeiColumn> = [
    { label: 'Nom du fichier', column: 'NOM_DU_DOCUMENT' },
    { label: 'Type de document', column: 'DOCUMENT_TYPE' },
    { label: 'Nombre de pages', column: 'DWPAGECOUNT'},
    { label: 'Date du dépôt', column: 'DATE_DU_DEPOT', type: 'date'},
    {
      label: '',
      column: 'actions',
      canUpdate: false,
      canDelete : this.authService.hasPermission(ENUM_PERMISSIONS.DELETE_FM_REF_PURCHASE_SUPPLIER_DOCUMENT),
      canDownload : this.authService.hasPermission(ENUM_PERMISSIONS.DOWNLOAD_FM_REF_PURCHASE_SUPPLIER_DOCUMENT)
    }
  ];
  displayedColumns = [];

  constructor(public service: SupplierService, private authService: AuthService, private gedService: GedService, public dialog: MatDialog, public fileSaver: FileSaverService ) {
    super(GED_CONFIG.supplier, gedService, service, dialog, fileSaver);
    this.displayedColumns = this.feisColumns.map(x => x.column);
  }

  initForm() {
    this.form = new FormGroup({
      documentType: new FormControl(null, [Validators.required]),
      predikId: new FormControl(this.id, [Validators.required]),
    });
    this.loading = false;
  }
}
