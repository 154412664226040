<ng-container *ngIf="(contractService.entity$ | async) as contract">
    <div *ngIf="contract?.creationStep === 3; else contractValidatedTemplate" class="flex flex-col w-full gap-6 mt-6"
         [formGroup]="form">
        <div class="flex flex-row-reverse gap-2">
            <button [disabled]="isCgaNotValidated()" color='primary' mat-raised-button type='button'
                    (click)="updateStep(4)">ENREGISTRER
            </button>
        </div>
        <div class="flex flex-row w-full gap-6">
            <div class="card w-full">
                <div class='flex flex-row px-gutter py-4 border-b'>
                    <h2 class='title m-0'>Pérennité et évolution des prix</h2>
                </div>
                <div class="px-gutter py-4 flex flex-col gap-4" [formGroup]="sustainabilityAndPriceTransForm">
                    <base-form-input
                            @fadeInRight
                            appearance="outline"
                            class="w-full"
                            type="select"
                            [items]="contractSustainabilityPrice"
                            label='Pérennité des prix'
                            controlName='sustainabilityPrice'>
                    </base-form-input>
                    <div *ngIf="sustainabilityAndPriceTransForm.get('sustainabilityPrice').value === contractSustainabilityPriceEnum.REVIEWABLE"
                         class="flex flex-row w-full items-center">
                        <p>Evolution des prix</p>
                        <mat-divider class='w-full ml-4 self-center border-gray'></mat-divider>
                    </div>
                    <base-form-input
                            @fadeInRight
                            *ngIf="sustainabilityAndPriceTransForm.get('sustainabilityPrice').value === contractSustainabilityPriceEnum.REVIEWABLE"
                            appearance="outline"
                            class="w-full"
                            type="select"
                            [items]="contractTypeOfRevision"
                            label='Type de révision'
                            controlName='typeOfRevision'>
                    </base-form-input>
                    <div class="flex items-center">

                        <base-form-input
                                @fadeInRight
                                *ngIf="sustainabilityAndPriceTransForm.get('typeOfRevision').value === contractTypeOfRevisionEnum.FIXED"
                                appearance="outline"
                                class="w-1/2"
                                type="slide-toggle"
                                label='Révision globale'
                                controlName='globalRevision'>
                        </base-form-input>

                        <base-form-input
                                @fadeInRight
                                *ngIf="sustainabilityAndPriceTransForm.get('globalRevision').value && (sustainabilityAndPriceTransForm.get('typeOfRevision').value === contractTypeOfRevisionEnum.FIXED)"
                                appearance="outline"
                                class="w-1/2"
                                type="number"
                                [minNumber]="null"
                                label='Montant de la révision (en %)'
                                controlName='revisionAmount'>
                        </base-form-input>
                    </div>
                    <base-form-input
                            @fadeInRight
                            *ngIf="sustainabilityAndPriceTransForm.get('typeOfRevision').value === contractTypeOfRevisionEnum.VARIABLE"
                            appearance="outline"
                            class="w-full"
                            label='Formule de révision'
                            controlName='revisionForm'>
                    </base-form-input>
                </div>
            </div>
            <ng-container
                    *ngIf="contract?.sustainabilityAndPriceTrans?.sustainabilityPrice === contractSustainabilityPriceEnum.REVIEWABLE">
                <div class="card w-full" [formGroup]="priceRevisionForm">
                    <div class='flex flex-row px-gutter py-4 border-b'>
                        <h2 class='title m-0'>Révision des prix</h2>
                    </div>
                    <div class="px-gutter py-4 flex flex-col gap-4">
                        <!--<div class='flex flex-row w-full mt-4'>
                            <base-form-input
                                    @fadeInRight
                                    appearance="outline"
                                    class="w-1/2 mr-4"
                                    label="À partir de "
                                    controlName='startDate'
                                    type="date"
                                    [dateFilter]="revisionStartDateFilter"

                            >
                            </base-form-input>
                            <base-form-input
                                    @fadeInRight
                                    appearance="outline"
                                    class="w-1/2"
                                    label="Date de fin d'application"
                                    controlName='endDate'
                                    type="date"
                                    [dateFilter]="revisionEndDateFilter"


                            >
                            </base-form-input>
                        </div>-->
                        <div class="flex flex-col sm:flex-row">
                            <base-form-input
                                    @fadeInRight
                                    appearance="outline"
                                    class='sm:mr-3 flex-auto'
                                    label='Répétition'
                                    type='select'
                                    [items]='recurrenceEnum'
                                    displayProperty='label'
                                    controlName='recurrence'>
                            </base-form-input>
                            <ng-container>
                    <span class='flex-auto self-center'>
                        Tou(te)s les
                    </span>
                                <base-form-input
                                        @fadeInRight
                                        appearance="outline"
                                        class='sm:ml-3 flex-auto'
                                        [label]='repeatLabel'
                                        type='number'
                                        controlName='repeat'>
                                </base-form-input>
                            </ng-container>
                        </div>

                        <ng-container *ngIf="anniversaryCheckboxActive">
                            <base-form-input
                                    label='Date anniversaire'
                                    type='checkbox'
                                    controlName='isAnniversaryDate'
                                    (click)="setAnniversaryDate();"
                            >
                            </base-form-input>
                        </ng-container>

                        <base-form-input
                                *ngIf='autoCompleteIsActive'
                                @fadeInRight
                                appearance="outline"
                                label='Activé le'
                                [items]='autoCompleteValue'
                                [type]='autoCompleteType'
                                [readonly]='priceRevisionForm.get("isAnniversaryDate").value'
                                [displayProperty]='autoCompleteDisplayProperty'
                                controlName='activated'>
                        </base-form-input>

                        <base-form-input
                                *ngIf='autocompleteMonth'
                                appearance="outline"
                                label='Mois'
                                [items]='monthEnum'
                                [readonly]='priceRevisionForm.get("isAnniversaryDate").value'
                                type='autocomplete'
                                displayProperty='label'
                                controlName='month'>
                        </base-form-input>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="card w-full">
            <div class='flex flex-row px-gutter py-4 border-b'>
                <h2 class='title m-0'>Frais annexes</h2>
            </div>
            <div class="px-gutter py-4 flex flex-col w-full gap-4">
                <ng-container [formGroup]="additionalCostAddForm" *ngIf="availableGenericAdditionalCosts?.length > 0">
                    <div class="flex flex-row w-full gap-4 items-center justify-between">
                        <base-form-input
                                @fadeInRight
                                class="w-full"
                                appearance="outline"
                                label="Frais annexes"
                                controlName="additionalCost"
                                type="select"
                                displayProperty="label,typeAdditionalCosts"
                                [displayPropertyObject]="showCustomAdditionalCosts.bind(this)"
                                [items]="availableGenericAdditionalCosts"
                                [disableAutoSelect]="true"
                        >
                        </base-form-input>
                        <!--                        <base-form-input-->
                        <!--                                @fadeInRight-->
                        <!--                                class="w-full"-->
                        <!--                                appearance="outline"-->
                        <!--                                disabled="true"-->
                        <!--                                label="Valeur numérique en €"-->
                        <!--                                controlName="numValue"-->
                        <!--                                type="number"-->
                        <!--                        >-->
                        <!--                        </base-form-input>-->
                        <!--                        <mat-form-field @fadeInRight class="w-full" appearance="outline">-->
                        <!--                            <mat-label>Par commande</mat-label>-->
                        <!--                            <input disabled matInput>-->
                        <!--                        </mat-form-field>-->
                        <!--                        <mat-form-field @fadeInRight class="w-full" appearance="outline">-->
                        <!--                            <mat-label>Forfaitaire ou à définir</mat-label>-->
                        <!--                            <input disabled matInput>-->
                        <!--                        </mat-form-field>-->
                        <!--                        <button @scaleIn [class.invisible]="true" class="mb-6" mat-icon-button>-->
                        <!--                            <mat-icon color="warn">cancel</mat-icon>-->
                        <!--                        </button>-->
                    </div>
                </ng-container>

                <ng-container formArrayName="additionalCosts"
                              *ngFor="let additionalCost of additionalCostsForm.controls; let i = index">
                    <ng-container [formGroup]="additionalCost">
                        <div class="flex flex-row w-full gap-4 items-center justify-between">
                            <base-form-input
                                    @fadeInRight
                                    appearance="outline"
                                    class="w-1/4"
                                    label="Frais annexes"
                                    controlName="additionalCost"
                                    type="autocomplete"
                                    displayProperty="label"
                                    [readonly]="true"
                                    [items$]="genericAdditionalCosts$"
                                    [disableAutoSelect]="true"
                            >
                            </base-form-input>
                            <mat-form-field @fadeInRight class="w-1/4" appearance="outline">
                                <mat-label>Forfaitaire ou à définir</mat-label>
                                <input disabled matInput [value]="additionalCost.value?.additionalCost?.type">
                            </mat-form-field>
                            <mat-form-field @fadeInRight class="w-1/4" appearance="outline">
                                <mat-label>Par commande</mat-label>
                                <input disabled matInput>
                            </mat-form-field>
                            <base-form-input
                                    *ngIf="additionalCost.value.additionalCost.type !== 'A définir'"
                                    @fadeInRight
                                    class="w-1/4"
                                    appearance="outline"
                                    label="Valeur numérique HT en €"
                                    controlName="numValueHT"
                                    type="number"
                            >
                            </base-form-input>
                            <base-form-input
                                    *ngIf="additionalCost.value.additionalCost.type !== 'A définir'"
                                    @fadeInRight
                                    [disabled]="true"
                                    class="w-1/4"
                                    appearance="outline"
                                    label="Valeur numérique TTC en €"
                                    controlName="numValueTTC"
                                    type="number"
                            >
                            </base-form-input>
                          <!-- FAUX l'information complémentaire ne doit pas dépdentre de s'il est ou non l'otherExpenses type. Mais ça dépend de la valeur "Information complémentaire -->
                            <base-form-input
                                *ngIf="additionalCost.value.additionalCost?.additionalInformation"
                                label="Information complémentaire"
                                controlName="additionalInformation"
                                appearance="outline"
                            >
                            </base-form-input>

                            <button @scaleIn class="mb-6" mat-icon-button
                                    (click)="removeAdditionalCostControl(i)">
                                <mat-icon color="warn">cancel</mat-icon>
                            </button>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="flex flex-row w-full gap-6">
            <div class="card w-full">
                <div class='flex flex-row px-gutter py-4 border-b'>
                    <h2 class='title m-0'>Livraison</h2>
                </div>

                <div class="flex flex-row w-full gap-6">
                    <div class="card w-full">
                        <div class='flex flex-row px-gutter py-4'>
                            <h1 class='title m-1'>Frais de livraison</h1>
                        </div>

                        <div class="px-gutter py-4 flex flex-col gap-4" [formGroup]="deliveryCostForm">
                            <base-form-input
                                    @fadeInRight
                                    appearance="outline"
                                    class='sm:mr-3 flex-auto'
                                    label='Frais de livraison'
                                    type='select'
                                    [items]='deliveryCostEnum'
                                    controlName='deliveryCost'>
                            </base-form-input>
                            <div
                                    class="flex flex-row w-full gap-4 items-center"
                                    *ngIf="deliveryCostForm.get('deliveryCost').value === deliveryCostsEnum.FREE_SHIPPING_FROM"
                            >
                                <p>Montant de commande</p>
                                <base-form-input
                                        @fadeInRight
                                        appearance="outline"
                                        class='sm:mr-3 flex-auto'
                                        label='Valeur numérique en € HT'
                                        type="number"
                                        controlName='fromValue'>
                                </base-form-input>
                            </div>
                            <div
                                    class="flex flex-row w-full gap-4 items-center"
                                    *ngIf="(deliveryCostForm.get('deliveryCost').value === deliveryCostsEnum.PACKAGE) || ((deliveryCostForm.get('deliveryCost').value === deliveryCostsEnum.FREE_SHIPPING_FROM) && (deliveryCostForm.get('fromValue').value > 0))"
                            >
                                <p> {{
                                        deliveryCostForm.get('deliveryCost').value === deliveryCostsEnum.PACKAGE ?
                                            'forfait dû de' : 'En dessous de ce montant, forfait dû de'
                                    }}
                                </p>

                                <base-form-input
                                        @fadeInRight
                                        appearance="outline"
                                        class='sm:mr-3 flex-auto'
                                        label='Valeur numérique en € HT'
                                        type="number"
                                        controlName='packageValue'>
                                </base-form-input>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-row w-full gap-6">
                    <div class="card w-full">
                        <div class='flex flex-row px-gutter py-4'>
                            <h1 class='title m-1'>Délais de livraison</h1>
                        </div>


                        <div class="px-gutter py-4 flex flex-col gap-4" [formGroup]="deliveryTimeForm">
                            <base-form-input
                                    @fadeInRight
                                    appearance="outline"
                                    class='sm:mr-3 flex-auto'
                                    label='Délai de livraison théorique urgent (en nb de jours)'
                                    type='number'
                                    [isInteger]="true"
                                    controlName='urgentTheoreticalDeliveryValue'
                                    [maxNumber]="this.deliveryTimeForm.controls?.standardTheoreticalDeliveryValue?.value"
                            >
                            </base-form-input>
                            <base-form-input
                                    @fadeInRight
                                    appearance="outline"
                                    class='sm:mr-3 flex-auto'
                                    label='Délai de livraison théorique standard (en nb de jours)'
                                    type='number'
                                    controlName='standardTheoreticalDeliveryValue'
                                    [isInteger]="true"
                                    >
                            </base-form-input>
                        </div>

                    </div>
                </div>
            </div>
            <div class="card w-full">
                <div class='flex flex-row px-gutter py-4 border-b'>
                    <h2 class='title m-0'>Conditions particulières</h2>
                </div>
                <div class="flex flex-col gap-4">
                    <quill-editor
                            class='h-20'
                            placeholder='Conditions particulières'
                            [modules]="quillConfig"
                            [formControl]='form?.controls?.specialConditions'
                    >
                    </quill-editor>
                </div>
            </div>
        </div>
    </div>
    <ng-template #contractValidatedTemplate>
        <div class="flex flex-col w-full gap-6 mt-6">
            <div class="flex flex-row-reverse gap-2">
                <button *ngIf="!(contract?.status===ContractStatusEnum.VALIDATED) && !(contract?.status===ContractStatusEnum.EXPIRED)"
                        color='primary' mat-raised-button type='button' (click)="switchToUpdateMode()">MODIFIER
                </button>
            </div>
            <div class="flex flex-row w-full gap-6">
                <div class="card w-full">
                    <div class='flex flex-row px-gutter py-4 border-b'>
                        <h2 class='title m-0'>Pérennité et évolution des prix</h2>
                    </div>

                    <div class="px-gutter py-4 flex flex-col gap-4">
                        <details-line-base
                                hint='Pérennité des prix'
                                [label]='contract?.sustainabilityAndPriceTrans?.sustainabilityPrice'>
                        </details-line-base>

                        <!-- Affiche des détails supplémentaires si le prix est révisable -->
                        <ng-container
                                *ngIf="contract?.sustainabilityAndPriceTrans.sustainabilityPrice === contractSustainabilityPriceEnum.REVIEWABLE">
                            <details-line-base
                                    hint='Type de révision'
                                    [label]='contract?.sustainabilityAndPriceTrans.typeOfRevision'>
                            </details-line-base>

                            <details-line-base
                                    *ngIf="contract?.sustainabilityAndPriceTrans.typeOfRevision === contractTypeOfRevisionEnum.FIXED"
                                    hint='Révision globale'
                                    [label]='contract?.sustainabilityAndPriceTrans.globalRevision ? "Oui" : "Non"'>
                            </details-line-base>

                            <!-- Affiche le montant de la révision si la révision est fixe -->
                            <details-line-base
                                    *ngIf="contract?.sustainabilityAndPriceTrans.typeOfRevision === contractTypeOfRevisionEnum.FIXED && contract?.sustainabilityAndPriceTrans.globalRevision"
                                    hint='Montant de la révision (en %)'
                                    [label]='contract?.sustainabilityAndPriceTrans.revisionAmount'>
                            </details-line-base>


                            <!-- Affiche la formule de révision si la révision est variable -->
                            <details-line-base
                                    *ngIf="contract?.sustainabilityAndPriceTrans.typeOfRevision === contractTypeOfRevisionEnum.VARIABLE"
                                    hint='Formule de révision'
                                    [label]='contract?.sustainabilityAndPriceTrans.revisionForm'>
                            </details-line-base>
                        </ng-container>
                    </div>
                </div>
                <ng-container
                        *ngIf="contract?.sustainabilityAndPriceTrans?.sustainabilityPrice === contractSustainabilityPriceEnum.REVIEWABLE">
                    <div class="card w-full">
                        <div class='flex flex-row px-gutter py-4 border-b'>
                            <h2 class='title m-0'>Révision des prix</h2>
                        </div>
                        <div class="px-gutter py-4 flex flex-col gap-4">

                            <!-- Si la pérennité des prix est fixe, on affiche un message indiquant l'absence de révision -->
                            <!--                            <ng-container-->
                            <!--                                    *ngIf="contract?.sustainabilityAndPriceTrans.sustainabilityPrice === contractSustainabilityPriceEnum.FIRM">-->
                            <!--                                <div class="text-center">-->
                            <!--                                    <p>Aucune révision des prix n'est prévue, car la pérennité des prix est fixée.</p>-->
                            <!--                                </div>-->
                            <!--                            </ng-container>-->

                            <!-- Sinon, on affiche les détails de la révision des prix -->
                            <ng-container
                                    *ngIf="contract?.sustainabilityAndPriceTrans.sustainabilityPrice !== contractSustainabilityPriceEnum.FIRM">

                                <!--                                Carte 1326, l'affichage de la date de début de révision n'est plus à affiché-->
                                <!--                                <details-line-base-->
                                <!--                                        hint='Date de début de la révision'-->
                                <!--                                        type='date'-->
                                <!--                                        [label]='contract?.startValidityDate'>-->
                                <!--                                </details-line-base>-->
                                <details-line-base
                                        hint="Date de fin de la révision"
                                        type='date'
                                        [label]='contract?.endValidityDate'>
                                </details-line-base>
                                <details-line-base
                                        hint='Répétition'
                                        [label]='contract?.priceRevision?.recurrence | recurrenceTranslation'>
                                </details-line-base>

                                <details-line-base *ngIf='contract?.priceRevision?.repeat'
                                                   [label]='getOccurrenceText(contract?.priceRevision)'
                                                   hint='Occurrence'></details-line-base>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="card w-full">
                <div class='flex flex-row px-gutter py-4 border-b'>
                    <h2 class='title m-0'>Frais annexes</h2>
                </div>
                <div class="px-gutter py-4 flex flex-row justify-between w-full gap-4"
                     *ngFor="let additionalCost of contract?.additionalCosts; let i = index">
                    <details-line-base
                            class="w-1/4"
                            hint='Frais annexes'
                            [label]='additionalCost?.additionalCost?.label'
                    >
                    </details-line-base>
                    <details-line-base
                            class="w-1/4"
                            hint='Forfaitaire ou à définir'
                            [label]='additionalCost?.additionalCost?.type'>
                    </details-line-base>
                    <details-line-base
                            class="w-1/4"
                            label='Par commande'
                    >
                    </details-line-base>
                    <details-line-base
                            *ngIf="additionalCost.additionalCost.type !== 'A définir'"
                            class="w-1/4"
                            hint='Valeur numérique HT en €'
                            [label]='additionalCost?.numValueHT'
                    >
                    </details-line-base>
                    <details-line-base
                            *ngIf="additionalCost.additionalCost.type !== 'A définir'"
                            class="w-1/4"
                            hint='Valeur numérique TTC en €'
                            [label]='additionalCost?.numValueTTC'
                    >
                    </details-line-base>
                    <details-line-base
                            *ngIf="additionalCost?.additionalInformation"
                            class="w-1/4"
                            hint='Information complémentaire'
                            [label]='additionalCost?.additionalInformation'
                    >
                    </details-line-base>
                </div>

            </div>
            <div class="flex flex-row w-full gap-6">
                <div class="card w-full">
                    <div class='flex flex-row px-gutter py-4 border-b'>
                        <h2 class='title m-0'>Livraison</h2>
                    </div>
                    <div class="px-gutter py-4 flex flex-col gap-4">

                        <div class='flex flex-row px-gutter py-4 border-b'>
                            <h2 class='title m-0'>Frais de livraison</h2>
                        </div>

                        <details-line-base
                                hint='Type de frais de livraison'
                                [label]='contract?.deliveryCost?.deliveryCost'>
                        </details-line-base>

                        <ng-container
                                *ngIf="contract?.deliveryCost?.deliveryCost === deliveryCostsEnum.PACKAGE || (contract?.deliveryCost?.deliveryCost === deliveryCostsEnum.FREE_SHIPPING_FROM && contract?.deliveryCost?.fromValue > 0)">
                            <details-line-base
                                    hint='Forfait de livraison'
                                    [label]='contract?.deliveryCost?.packageValue | currency:"EUR"'>
                            </details-line-base>
                        </ng-container>

                        <div class='flex flex-row px-gutter py-4 border-b'>
                            <h2 class='title m-0'>Délais de livraison</h2>
                        </div>

                        <details-line-base
                                hint='Délai de livraison urgent'
                                [label]='contract?.deliveryTime?.urgentTheoreticalDeliveryValue'>
                        </details-line-base>

                        <details-line-base
                                hint='Délai de livraison standard'
                                [label]='contract?.deliveryTime?.standardTheoreticalDeliveryValue'>
                        </details-line-base>

                        <!-- Détails supplémentaires basés sur le type de frais de livraison -->
                        <ng-container
                                *ngIf="contract?.deliveryCost?.deliveryCost === deliveryCostsEnum.FREE_SHIPPING_FROM">
                            <details-line-base
                                    hint='Valeur minimale pour la livraison gratuite'
                                    [label]='contract?.deliveryCost?.fromValue | currency:"EUR"'>
                            </details-line-base>
                        </ng-container>
                        <!-- Ajoutez ici d'autres détails pertinents sur les frais de livraison si nécessaire -->
                    </div>
                </div>
                <div class="card w-full">
                    <div class='flex flex-row px-gutter py-4 border-b'>
                        <h2 class='title m-0'>Conditions particulières</h2>
                    </div>
                    <div class="px-gutter py-4 flex flex-col gap-4">
                        <details-line-base
                                type="html"
                                [label]='contract?.specialConditions'>
                        </details-line-base>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>

<ng-template #emptyTemplate>
    <div class="w-1/4"></div>
</ng-template>
