import moment from 'moment';

export const DateFormat = 'dd/MM/yyyy';
export const DateTimeFormat = 'dd/MM/yyyy - HH:mm';

export class DateValidationHelper {

    static checkDateIsToday(date: Date): boolean {
        if (date === undefined) return false;

        const today = moment().utc().startOf('day');
        const dateToCheck = moment(date).utc().add(2, 'hours').startOf('day');

        return dateToCheck.isSame(today, 'day');
    }

    static checkDateIsSameOrBeforeToday(date: Date): boolean {
        if (date === undefined) return false;

        const today = moment().utc().startOf('day');
        const dateToCheck = moment(date).utc().add(2, 'hours').startOf('day');

        return dateToCheck.isSameOrBefore(today, 'day');
    }

    static validateDate(date: string | Date | null): Date | null {
        if (!date) {
            return null;
        }

        const parsedDate = new Date(date);
        if (isNaN(parsedDate.getTime())) {
            return null;
        }

        // This methode check if the date is the epoch start (a date probably invalid)
        const isEpochStart = parsedDate.getTime() === 0;
        if (isEpochStart) {
            console.log('Invalid date return to null value :', date);
        }
        return isEpochStart ? null : parsedDate;
    }
}
