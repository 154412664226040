<!--BASIC-->
<ng-container
        *ngIf='!_specialColumn.includes(this.column) && (!type || (type === "text")) && showSort'
        [matColumnDef]='column'>
    <th *matHeaderCellDef [disabled]="!canSort" [ngClass]="{'text-primary': isPredik}" mat-header-cell
        mat-sort-header>{{ label }}
    </th>

    <ng-container *ngIf="!isExpiredOrderContractLine">
        <td (click)='clickRow(element)' *matCellDef='let element'
            [ngClass]="{'text-primary': isPredik} "
            [routerLink]='canSeeDetails ? routerLink(element) : null'
            mat-cell>
            <span [className]="'status-badge-' + (statusClassFunc ? statusClassFunc(element) : '')">
                {{ previousDisplay }}{{ customDisplay !== undefined ? customDisplay : display(element) }}{{ additionalDisplay }}
            </span>
            <span *ngIf="customExtraHtml" [innerHTML]="element.customExtraHtml"></span>
        </td>
    </ng-container>

    <!--    Pour les commandes (orders) et les lignes rouges lors de l'expriation du contract-->
    <ng-container *ngIf="isExpiredOrderContractLine">
        <td (click)='clickRow(element)' *matCellDef='let element'
            [ngClass]="{'text-primary': isPredik, 'disabled': checkIfAllWaiting(element)} "
            [ngStyle]="contractExpiringSoon(element?.contract) && !element.statusPrestationLineIsWaiting ? {'background-color': 'rgba(255, 0, 0, 0.3)'} :
                        element?.contract?.status === 'EXPIRED' && !element.statusPrestationLineIsWaiting ? {'color': 'red'} :
                        element?.statusPrestationLineIsWaiting ? {'background-color': 'rgba(255, 245, 230, 1)'} : null"
            [routerLink]='canSeeDetails ? routerLink(element) : null'
            mat-cell>
            <span [className]="'status-badge-' + (statusClassFunc ? statusClassFunc(element) : '')">
                {{ previousDisplay }}{{ customDisplay !== undefined ? customDisplay : display(element) }}{{ additionalDisplay }}
            </span>
            <span
                    *ngIf="customExtraHtml" [innerHTML]="element.customExtraHtml"></span>
        </td>
    </ng-container>

</ng-container>

<ng-container
        *ngIf='!_specialColumn.includes(this.column) && (!type || (type === "text")) && !showSort'
        [matColumnDef]='column'>
    <th *matHeaderCellDef [ngClass]="{'text-primary': isPredik}" mat-header-cell>{{ label }}</th>
    <ng-container *ngIf="!isExpiredOrderContractLine">
    <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
        [routerLink]='canAccess() ? routerLink(element) : null'
        mat-cell>
            <span [className]="'status-badge-' + (statusClassFunc ? statusClassFunc(element) : '')">
                {{ previousDisplay }}{{ customDisplay !== undefined ? customDisplay : display(element) }}{{ additionalDisplay }}
            </span>
    </td>
    </ng-container>
    <ng-container *ngIf="isExpiredOrderContractLine">
        <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
            [ngStyle]="contractExpiringSoon(element?.contract) && !element.statusPrestationLineIsWaiting ? {'background-color': 'rgba(255, 0, 0, 0.3)'} :
                        element?.contract?.status === 'EXPIRED' && !element.statusPrestationLineIsWaiting ? {'color': 'red'} :
                        element?.statusPrestationLineIsWaiting ? {'background-color': 'rgba(255, 245, 230, 1)'} : null"
            [routerLink]='canAccess() ? routerLink(element) : null'
            mat-cell>
            <span [className]="'status-badge-' + (statusClassFunc ? statusClassFunc(element) : '')">
                {{ previousDisplay }}{{ customDisplay !== undefined ? customDisplay : display(element) }}{{ additionalDisplay }}
            </span>
        </td>
    </ng-container>
</ng-container>

<!-- Checkbox Column -->
<ng-container
        *ngIf='column === "selection"' matColumnDef='selection'>
    <th *matHeaderCellDef mat-header-cell>
        <mat-checkbox (change)="$event ? toggleAllRows() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [disabled]="selectAllDisabled"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [matTooltip]="checkboxTooltip"
        >
        </mat-checkbox>
        {{ additionalDisplay }}
    </th>
    <ng-container *ngIf="!isExpiredOrderContractLine">
        <td *matCellDef="let element" mat-cell>

            <mat-checkbox *ngIf="canSelectCheckBoxRows" (change)="toggleRow($event, element)"
                          (click)="$event.stopPropagation()"
                          [checked]="selection.isSelected(element)"
                          [disabled]="element.dataTableSelectDisabled || element.isActive === false"
            >
            </mat-checkbox>
        </td>
    </ng-container>
    <ng-container *ngIf="isExpiredOrderContractLine">
        <td *matCellDef="let element" mat-cell
            [ngStyle]="contractExpiringSoon(element?.contract) && !element.statusPrestationLineIsWaiting ? {'background-color': 'rgba(255, 0, 0, 0.3)'} :
                        element?.contract?.status === 'EXPIRED' && !element.statusPrestationLineIsWaiting ? {'color': 'red'} :
                        element?.statusPrestationLineIsWaiting ? {'background-color': 'rgba(255, 245, 230, 1)'} : null"
        >

            <mat-checkbox *ngIf="canSelectCheckBoxRows" (change)="toggleRow($event, element)"
                          (click)="$event.stopPropagation()"
                          [checked]="selection.isSelected(element)"
                          [disabled]="element.dataTableSelectDisabled || element.isActive === false || checkIfAllWaiting(element)"
            >
            </mat-checkbox>
        </td>
    </ng-container>
</ng-container>

<!-- Favorite Column -->

<ng-container *ngIf='column === "favorite"' matColumnDef='favorite'>
    <th *matHeaderCellDef [disabled]="!canSort" [ngClass]="{'text-primary': isPredik}" mat-header-cell
        mat-sort-header>{{ label }}
    </th>
    <td *matCellDef="let element" mat-cell>
        <button mat-icon-button (click)="toggleFavorite(element)">
            <mat-icon *ngIf="isFavorite(element)">star</mat-icon>
            <mat-icon *ngIf="!isFavorite(element)">star_border</mat-icon>
        </button>
    </td>
</ng-container>

<ng-container *ngIf='type === "favorite"' matColumnDef='favorite'>
    <th *matHeaderCellDef [disabled]="!canSort" [ngClass]="{'text-primary': isPredik}" mat-header-cell
        mat-sort-header>{{ label }}
    </th>
    <td *matCellDef="let element" mat-cell>
        <button mat-icon-button (click)="toggleFavorite(element)">
            <mat-icon *ngIf="isFavorite(element)">star</mat-icon>
            <mat-icon *ngIf="!isFavorite(element)">star_border</mat-icon>
        </button>
    </td>
</ng-container>

<ng-container *ngIf='type === "bubble"' matColumnDef='bubble'>
    <th *matHeaderCellDef [disabled]="!canSort" [ngClass]="{'text-primary': isPredik}" mat-header-cell
        mat-sort-header>{{ label }}
    </th>
    <td *matCellDef="let element" mat-cell>
        <div
            [className]="'status-badge-' + statusClassFunc(element)"
        >
            vide
        </div>
    </td>
</ng-container>

<!--NUMBER-->
<ng-container
        *ngIf='type == "number" && showSort'
        [matColumnDef]='column'>
    <th *matHeaderCellDef [disabled]="!canSort" [ngClass]="{'text-primary': isPredik}" mat-header-cell
        mat-sort-header>{{ label }}
    </th>
    <ng-container *ngIf="!isExpiredOrderContractLine">
        <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}" class="text-right"
            [routerLink]='canAccess() ? routerLink(element) : null'
            mat-cell>
            {{ previousDisplay }}
            {{ customDisplay !== undefined ? customDisplay : isNumber(display(element)) ? (display(element)  | number: '1.' + maxDecimal + '-' + maxDecimal) : display(element) }}
            <!--        {{ (round(display(element)) || round(display(element)) === 0) || customDisplay !== undefined ? '' : '-' | number : '1.' + maxDecimal + '-' + maxDecimal }}-->
            {{ additionalDisplay }}
        </td>
    </ng-container>
    <ng-container *ngIf="isExpiredOrderContractLine">
        <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"  class="text-right"
            [ngStyle]="contractExpiringSoon(element?.contract) && !element.statusPrestationLineIsWaiting ? {'background-color': 'rgba(255, 0, 0, 0.3)'} :
                        element?.contract?.status === 'EXPIRED' && !element.statusPrestationLineIsWaiting ? {'color': 'red'} :
                        element?.statusPrestationLineIsWaiting ? {'background-color': 'rgba(255, 245, 230, 1)'} : null"
            [routerLink]='canAccess() ? routerLink(element) : null'
            mat-cell>
            {{ previousDisplay }}
            {{ customDisplay !== undefined ? customDisplay : isNumber(display(element)) ? (display(element)  | number: '1.' + maxDecimal + '-' + maxDecimal) : display(element) }}
            <!--        {{ (round(display(element)) || round(display(element)) === 0) || customDisplay !== undefined ? '' : '-' | number : '1.' + maxDecimal + '-' + maxDecimal }}-->
            {{ additionalDisplay }}
        </td>

    </ng-container>
</ng-container>

<ng-container *ngIf='type == "number" && !showSort' [matColumnDef]='column'>
    <th *matHeaderCellDef [ngClass]="{'text-primary': isPredik}" mat-header-cell>{{ label }}</th>
    <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"  class="text-right"
        [routerLink]='canAccess() ? routerLink(element) : null'
        mat-cell>
        {{ previousDisplay }}
        {{ customDisplay !== undefined ? customDisplay : isNumber(display(element)) ? (display(element)  | number: '1.' + maxDecimal + '-' + maxDecimal) : display(element) }}
        <!--        {{ (round(display(element)) || round(display(element)) === 0) || customDisplay !== undefined ? '' : '-' | number : '1.' + maxDecimal + '-' + maxDecimal }}-->
        {{ additionalDisplay }}
    </td>
</ng-container>


<!--TVA-->
<!--<ng-container *ngIf='type == "tva"' [matColumnDef]='column'>-->
<!--    <th *matHeaderCellDef [ngClass]="{'text-primary': isPredik}" mat-header-cell>{{ showLabelTva(element) }}</th>-->
<!--    <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"-->
<!--        [routerLink]='canAccess() ? routerLink(element) : null'-->
<!--        class="text-secondary font-medium"-->
<!--        mat-cell>-->
<!--        {{ previousDisplay }}-->
<!--        {{ customDisplay !== undefined ? customDisplay : isNumber(display(element)) ? (display(element)  | number: '1.' + maxDecimal + '-' + maxDecimal) : display(element) }}-->
<!--        &lt;!&ndash;        {{ (round(display(element)) || round(display(element)) === 0) || customDisplay !== undefined ? '' : '-' | number : '1.' + maxDecimal + '-' + maxDecimal }}&ndash;&gt;-->
<!--        {{ additionalDisplay }}-->
<!--    </td>-->
<!--</ng-container>-->

<!--DATE-->
<ng-container
        *ngIf='type == "date" && showSort'
        [matColumnDef]='column'>
    <th *matHeaderCellDef [disabled]="!canSort" [ngClass]="{'text-primary': isPredik}" mat-header-cell
        mat-sort-header>{{ label }}
    </th>
    <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
        [routerLink]='canAccess() ? routerLink(element) : null'
        mat-cell>
        <ng-container *ngIf='display(element) === "-"; else dateBlock'>
            {{ previousDisplay }}-{{ additionalDisplay }}
        </ng-container>
        <ng-template #dateBlock>
            <span [className]="'status-badge-' + (statusClassFunc ? statusClassFunc(element) : '')">
                {{ previousDisplay }}{{ display(element) | date: dateFormat }}{{ additionalDisplay }}
            </span>
        </ng-template>
    </td>
</ng-container>

<ng-container
        *ngIf='type == "date" && !showSort'
        [matColumnDef]='column'>
    <th *matHeaderCellDef [ngClass]="{'text-primary': isPredik}" mat-header-cell>{{ label }}</th>
    <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
        [routerLink]='canAccess() ? routerLink(element) : null'
        mat-cell>
        <ng-container *ngIf='display(element) === "-"; else dateBlock'>
            {{ previousDisplay }}-{{ additionalDisplay }}
        </ng-container>
        <ng-template #dateBlock>
            {{ previousDisplay }}{{ display(element) | date: dateFormat }}{{ additionalDisplay }}
        </ng-template>
    </td>
</ng-container>

<!--DATETIME-->
<ng-container
        *ngIf='type == "dateTime" && showSort'
        [matColumnDef]='column'>
    <th *matHeaderCellDef [disabled]="!canSort" [ngClass]="{'text-primary': isPredik}" mat-header-cell
        mat-sort-header>{{ label }}
    </th>
    <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
        [routerLink]='canAccess() ? routerLink(element) : null'
        mat-cell>
        <ng-container *ngIf='display(element) === "-"; else dateBlock'>
            {{ previousDisplay }}-{{ additionalDisplay }}
        </ng-container>
        <ng-template #dateBlock>
            {{ previousDisplay }}{{ display(element) | date: dateTimeFormat }}{{ additionalDisplay }}
        </ng-template>
    </td>
</ng-container>

<ng-container
        *ngIf='type == "dateTime" && !showSort'
        [matColumnDef]='column'>
    <th *matHeaderCellDef [ngClass]="{'text-primary': isPredik}" mat-header-cell>{{ label }}</th>
    <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
        mat-cell>
        <ng-container *ngIf='display(element) === "-"; else dateBlock'>
            {{ previousDisplay }}-{{ additionalDisplay }}
        </ng-container>
        <ng-template #dateBlock>
            {{ previousDisplay }}{{ display(element) | date: dateTimeFormat }}{{ additionalDisplay }}
        </ng-template>
    </td>
</ng-container>

<!--BOOLEAN-->
<ng-container *ngIf='type === "boolean" && showSort' [matColumnDef]='column'>
    <th *matHeaderCellDef [disabled]="!canSort" [ngClass]="{'text-primary': isPredik}" mat-header-cell mat-sort-header>
        {{ label }}
    </th>
    <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
        [routerLink]='canAccess() ? routerLink(element) : null'
        mat-cell>
        <a (click)="$event.stopPropagation(); onValueChange.emit({column, element})" *ngIf="elementIsTrue(element)"
           class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary-light"
           mat-icon-button>
            <mat-icon [icIcon]="icDone" size="18px"></mat-icon>
        </a>
        <a (click)="$event.stopPropagation(); onValueChange.emit({column, element})" *ngIf="!elementIsTrue(element)"
           class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-accent bg-accent-light"
           mat-icon-button>
            <mat-icon [icIcon]="icClose" size="18px"></mat-icon>
        </a>
    </td>
</ng-container>

<ng-container *ngIf='type === "boolean" && !showSort' [matColumnDef]='column'>
    <th *matHeaderCellDef [ngClass]="{'text-primary': isPredik}" mat-header-cell>
        {{ label }}
    </th>
    <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
        [routerLink]='canAccess() ? routerLink(element) : null'
        mat-cell>
        <a (click)="$event.stopPropagation(); onValueChange.emit(element[column])" *ngIf="element[column]"
           class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary-light"
           mat-icon-button>
            <mat-icon [icIcon]="icDone" size="18px"></mat-icon>
        </a>
        <a (click)="$event.stopPropagation(); onValueChange.emit(element[column])" *ngIf="!element[column]"
           class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-accent bg-accent-light"
           mat-icon-button>
            <mat-icon [icIcon]="icClose" size="18px"></mat-icon>
        </a>
    </td>
</ng-container>


<ng-container *ngIf='type === "isSpecialConditions" && showSort' [matColumnDef]='column'>
    <th *matHeaderCellDef [disabled]="!canSort" [ngClass]="{'text-primary': isPredik}" mat-header-cell mat-sort-header>
        {{ label }}
    </th>
    <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
        [routerLink]='canAccess() ? routerLink(element) : null'
        mat-cell>
        <a (click)="$event.stopPropagation(); onValueChange.emit({column, element})" *ngIf="elementIsNotNull(element)"
           class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary-light"
           mat-icon-button>
            <mat-icon [icIcon]="icDone" size="18px"></mat-icon>
        </a>
        <a (click)="$event.stopPropagation(); onValueChange.emit({column, element})" *ngIf="!elementIsNotNull(element)"
           class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-accent bg-accent-light"
           mat-icon-button>
            <mat-icon [icIcon]="icClose" size="18px"></mat-icon>
        </a>
    </td>
</ng-container>


<!--IS ACTIVE-->
<ng-container *ngIf='column === "isActive" && showSort' matColumnDef='isActive'>
    <th *matHeaderCellDef [disabled]="!canSort" [ngClass]="{'text-primary': isPredik}" mat-header-cell mat-sort-header>
        Is Active
    </th>
    <td *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
        [routerLink]='canAccess() ? routerLink(element) : null'
        mat-cell>
        <a (click)="$event.stopPropagation()" *ngIf="element.isActive"
           class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary-light"
           mat-icon-button>
            <mat-icon [icIcon]="icDone" size="18px"></mat-icon>
        </a>
        <a (click)="$event.stopPropagation()" *ngIf="!element.isActive"
           class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-accent bg-accent-light"
           mat-icon-button>
            <mat-icon [icIcon]="icClose" size="18px"></mat-icon>
        </a>
    </td>
</ng-container>

<ng-container *ngIf='column === "isActive" && !showSort' matColumnDef='isActive'>
    <th *matHeaderCellDef [disabled]="!canSort" [ngClass]="{'text-primary': isPredik}" mat-header-cell>
        Is Active
    </th>
    <td *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
        [routerLink]='canAccess() ? routerLink(element) : null'
        mat-cell>
        <a (click)="$event.stopPropagation()" *ngIf="element.isActive"
           class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary-light"
           mat-icon-button>
            <mat-icon [icIcon]="icDone" size="18px"></mat-icon>
        </a>
        <a (click)="$event.stopPropagation()" *ngIf="!element.isActive"
           class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-accent bg-accent-light"
           mat-icon-button>
            <mat-icon [icIcon]="icClose" size="18px"></mat-icon>
        </a>
    </td>
</ng-container>

<!--CATEGORY-->
<ng-container
        *ngIf='!_specialColumn.includes(this.column) && (!type || (type === "category")) && showSort'
        [matColumnDef]='column'>
    <th *matHeaderCellDef [disabled]="!canSort" [ngClass]="{'text-primary': isPredik}" mat-header-cell
        mat-sort-header>{{ label }}
    </th>
    <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
        [routerLink]='canAccess() ? routerLink(element) : null'

        mat-cell>
        {{ display(element) }}{{ additionalDisplay }}
    </td>
</ng-container>

<ng-container
        *ngIf='!_specialColumn.includes(this.column) && (!type || (type === "category")) && !showSort'
        [matColumnDef]='column'>
    <th *matHeaderCellDef [ngClass]="{'text-primary': isPredik}" mat-header-cell>{{ label }}</th>
    <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
        [routerLink]='canAccess() ? routerLink(element) : null'

        mat-cell>
        {{ display(element) }}{{ additionalDisplay }}
    </td>
</ng-container>

<!--TECHNICAL IDENTIFIER-->
<ng-container
        *ngIf='!_specialColumn.includes(this.column) && (!type || (type === "technicalIdentifier")) && showSort'
        [matColumnDef]='column'>
    <th *matHeaderCellDef [disabled]="!canSort" [ngClass]="{'text-primary': isPredik}" mat-header-cell
        mat-sort-header>{{ label }}
    </th>
    <td (click)='clickRow(element, $event)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
        [routerLink]='canAccess() ? routerLink(element) : null'

        mat-cell>
        <div class='flex flex-row justify-between items-center'>
            {{ display(element) }}{{ additionalDisplay }}
            <mat-icon [icIcon]="icBaselineContentCopy" class='ml-1 text-gray' width='16'></mat-icon>
        </div>
    </td>
</ng-container>

<ng-container
        *ngIf='!_specialColumn.includes(this.column) && (!type || (type === "technicalIdentifier")) && !showSort'
        [matColumnDef]='column'>
    <th *matHeaderCellDef [ngClass]="{'text-primary': isPredik}" mat-header-cell>{{ label }}</th>
    <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
        [routerLink]='canAccess() ? routerLink(element) : null'

        mat-cell>
        {{ display(element) }}{{ additionalDisplay }}
        <mat-icon [icIcon]="icBaselineContentCopy"></mat-icon>
    </td>
</ng-container>

<!--CATALOGUE-->
<ng-container
        *ngIf='!_specialColumn.includes(this.column) && (!type || (type === "catalogue")) && showSort'
        [matColumnDef]='column'>
    <th *matHeaderCellDef [disabled]="!canSort" [ngClass]="{'text-primary': isPredik}" mat-header-cell
        mat-sort-header>{{ label }}
    </th>
    <td (click)='clickRow(element, $event)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
        [routerLink]='canAccess() ? routerLink(element) : null'

        mat-cell>
        <div class='flex flex-row justify-between items-center'>
            <ng-container
                    *ngIf="status === 'enabled' && element.quantity !== '-' && !element.cannotUpdateQuantity; else disableValueSort">
                <mat-icon (click)='catalogueChange("remove", element)' [icIcon]="icRemove" class='ml-1 text-gray'
                          width='16'></mat-icon>
                <mat-form-field class="w-14 text-center">
                    <input (change)="catalogueChange('update', element, $event)" [value]='element.quantity' matInput
                           min='0'
                           type='number'>
                </mat-form-field>
                <mat-icon (click)='catalogueChange("add", element)' [icIcon]="icAdd" class='ml-1 text-gray'
                          width='16'></mat-icon>
            </ng-container>
            <ng-template #disableValueSort>
                {{ element.quantity }}
            </ng-template>
        </div>
    </td>
</ng-container>

<ng-container
        *ngIf='!_specialColumn.includes(this.column) && (!type || (type === "catalogue")) && !showSort'
        [matColumnDef]='column'>
    <th *matHeaderCellDef [ngClass]="{'text-primary': isPredik}" mat-header-cell>{{ label }}</th>
    <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
        [routerLink]='canAccess() ? routerLink(element) : null'

        mat-cell>
        <ng-container
                *ngIf="status === 'enabled' && element.quantity !== '-' && !element.cannotUpdateQuantity; else disableValueNoSort">
            <mat-icon (click)='catalogueChange("remove", element)' [icIcon]="icRemove" class='ml-1 text-gray'
                      width='16'></mat-icon>
            <mat-form-field class="w-14 text-center">
                <input (change)="catalogueChange('update', element, $event)" [value]='element.quantity' matInput min='0'
                       type='number'>
            </mat-form-field>
            <mat-icon (click)='catalogueChange("add", element)' [icIcon]="icAdd" class='ml-1 text-gray'
                      width='16'></mat-icon>
        </ng-container>
        <ng-template #disableValueNoSort>
            {{ element.quantity }}
        </ng-template>
    </td>
</ng-container>

<!--CATALOGUE NB CONTRACT-->
<ng-container *ngIf='type === "catalogueNbContract" && showSort' [matColumnDef]='column'>
    <th *matHeaderCellDef [disabled]="!canSort" [ngClass]="{'text-primary': isPredik}" mat-header-cell mat-sort-header>
        {{ label }}
    </th>
    <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
        [routerLink]='canAccess() ? routerLink(element) : null'

        mat-cell>
        <a (click)="$event.stopPropagation(); onValueChange.emit({column, element})"
           *ngIf="elementNbContractCatalogueIsTrue(element)"
           class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary-light"
           mat-icon-button>
            {{ element[column] }}
        </a>
        <a (click)="$event.stopPropagation(); onValueChange.emit({column, element})"
           *ngIf="!elementNbContractCatalogueIsTrue(element)"
           class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-accent bg-accent-light"
           mat-icon-button>
            {{ element[column] }}
        </a>
    </td>
</ng-container>

<ng-container *ngIf='type === "catalogueNbContract" && !showSort' [matColumnDef]='column'>
    <th *matHeaderCellDef [ngClass]="{'text-primary': isPredik}" mat-header-cell>
        {{ label }}
    </th>
    <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
        [routerLink]='canAccess() ? routerLink(element) : null'

        mat-cell>
        <a (click)="$event.stopPropagation(); onValueChange.emit(element[column])" *ngIf="element[column] > 0"
           class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary-light"
           mat-icon-button>
            {{ element[column] }}
        </a>
        <a (click)="$event.stopPropagation(); onValueChange.emit(element[column])" *ngIf="element[column] <= 0"
           class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-accent bg-accent-light"
           mat-icon-button>
            {{ element[column] }}
        </a>
    </td>
</ng-container>

<!--NOTIFICATION STATUS-->
<ng-container
        *ngIf='!_specialColumn.includes(this.column) && (!type || (type === "notificationStatus"))'
        [matColumnDef]='column'
        class='w-1'>
    <th *matHeaderCellDef [ngClass]="{'text-primary': isPredik}" mat-header-cell mat-sort-header>{{ label }}</th>
    <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
        [routerLink]='canAccess() ? routerLink(element) : null'
        mat-cell>
        <div *ngIf='element.status === _notificationStatus.UN_READ' class='rounded-full bg-primary w-4 h-4'></div>
    </td>
</ng-container>

<!--attentionRequiered -->
<ng-container
        *ngIf='!_specialColumn.includes(this.column) && (!type || (type === "attentionRequiered"))'
        [matColumnDef]='column'
        class='w-1'>
    <th *matHeaderCellDef [ngClass]="{'text-primary': isPredik}" mat-header-cell mat-sort-header>{{ label }}</th>
    <td (click)='clickRow(element)' *matCellDef='let element' [ngClass]="{'text-primary': isPredik}"
        [routerLink]='canAccess() ? routerLink(element) : null'

        mat-cell>
        <div *ngIf='element.isAttentionRequired'
             [matTooltip]="attentionRequieredTooltip || element.attentionRequieredTooltip"
             class='rounded-full bg-primary w-4 h-4'></div>
    </td>
</ng-container>

<!--ACTION-->
<ng-container *ngIf='column === "actions"' matColumnDef='actions'>
    <th *matHeaderCellDef [ngClass]="{'text-primary': isPredik}" mat-header-cell>{{ label }}</th>
    <td *matCellDef='let element' [ngClass]="{'text-primary': isPredik}" class='w-10' mat-cell>
        <div *ngIf="!element.noAction" style='text-align: right;'>
            <div>
                <span *ngIf="isProductPrestation && element?.contract?.status === 'EXPIRED'">
                    {{ actionText }}
                </span>

                <div *ngIf="(isProductPrestation && element?.contract?.status != 'EXPIRED') || !isProductPrestation">

                    <button *ngIf='canDelete && !element.isNotDeletable'
                            (click)="deleteItem(element)"
                            class="text-red" mat-icon-button
                    >
                        <mat-icon [icIcon]="icDelete" ></mat-icon>
                    </button>

                    <button *ngIf='canArchive || canUpdate || canDownload'
                            (click)='selectItem(element)'
                            [matMenuTriggerData]="{element: element}"
                            [matMenuTriggerFor]="columnActionMenu" mat-icon-button type='button'>
                        <mat-icon [icIcon]='icMoreVert'
                                  [matTooltipShowDelay]='1500'
                                  matTooltip='actions'
                        ></mat-icon>
                    </button>


                </div>
            </div>
        </div>
    </td>
</ng-container>
<mat-menu #columnActionMenu="matMenu" xPosition="before" yPosition="below">
    <ng-template let-element="element" matMenuContent>
        <ng-container *ngTemplateOutlet="actionsTemplate; context: {value: _selectedItem}"></ng-container>
        <!--        <button (click)="favoriteItem()" *ngIf='canFavorite && !canArchive && !element.isNotDeletable'-->
        <!--                class="mat-menu-item">-->
        <!--            <mat-icon [icIcon]="icStar"></mat-icon>-->
        <!--            Assigner favoris-->
        <!--        </button>-->

        <button (click)='changeReadStatus(element)' *ngIf='canChangeStatus && !canArchive && !element.isNotDeletable'
                class="mat-menu-item">
            <mat-icon [icIcon]="icEdit"></mat-icon>
            {{ element.status === 'Lu' ? 'Marquer comme Non lu' : 'Marquer comme Lu' }}
        </button>

        <button (click)='updateItem()' *ngIf='canUpdate' class="mat-menu-item">
            <mat-icon [icIcon]="icEdit"></mat-icon>
            Modifier
        </button>

        <button (click)='downloadItem(element)' *ngIf='canDownload' class="mat-menu-item">
            <mat-icon [icIcon]="icEdit"></mat-icon>
            Télécharger
        </button>

      <button (click)='duplicateItem()' *ngIf='canDuplicate' class="mat-menu-item">
            <mat-icon [icIcon]="icDuplicate"></mat-icon>
            Dupliquer
        </button>

<!--        the delete button has become a trash icon (PRED-1885)-->
<!--        <button (click)="deleteItem()" *ngIf='canDelete && !canArchive && !element.isNotDeletable'-->
<!--                class="mat-menu-item">-->
<!--            <mat-icon [icIcon]="icDelete"></mat-icon>-->
<!--            Supprimer-->
<!--        </button>-->

<!--        <button (click)="deleteItem()" *ngIf='canDelete && !canArchive && !element.isNotDeletable'-->
<!--                class="mat-menu-item">-->
<!--            <mat-icon [icIcon]="icDelete"></mat-icon>-->
<!--            Supprimer-->
<!--        </button>-->
        <button (click)="archiveItem()" *ngIf='canArchive && !element.isNotArchivable' class="mat-menu-item">
            <mat-icon [icIcon]="icArchive"></mat-icon>
            {{ (element.isArchive ? 'Désarchiver' : 'Archiver') }}
        </button>

<!--        <button *ngIf='canDuplicate' (click)="duplicateItem()" class="mat-menu-item">-->
<!--            <mat-icon>collections</mat-icon>-->
<!--            Dupliquer-->
<!--        </button>-->
        <!--    <button class="mat-menu-item" (click)="deleteItem()">-->
        <!--        <mat-icon [icIcon]="icDelete"></mat-icon>-->
        <!--        Switch isActive-->
        <!--    </button>-->
    </ng-template>

</mat-menu>

<ng-container *ngIf="(!type || (type === 'custom'))" [matColumnDef]="column">
    <th *matHeaderCellDef [disabled]="!canSort" [ngClass]="{'text-primary': isPredik}" mat-header-cell>
        <div *ngIf='customColHeader'>
            <ng-container *ngTemplateOutlet="customColumnHeaderTemplate; context: {$implicit: column}"></ng-container>
        </div>
        <div *ngIf='!customColHeader'>
            {{ label }}
        </div>
    </th>
    <ng-container *ngIf="!isExpiredOrderContractLine">
        <td *matCellDef='let element' [ngClass]="{'text-primary': isPredik}" [ngStyle]="{ 'width': width }"
            class='w-10 text-secondary' mat-cell>
            <ng-container
                    *ngTemplateOutlet="customColumnTemplate; context: {$implicit: column, element: element, status: status}"></ng-container>
        </td>
    </ng-container>
    <ng-container *ngIf="isExpiredOrderContractLine">
        <td *matCellDef='let element'
            [ngClass]="{'text-primary': isPredik, 'disabled-cell': checkIfAllWaiting(element)}"
            [style.opacity]="checkIfAllWaiting(element) ? 1 : 1"
            [style.pointer-events]="checkIfAllWaiting(element) ? 'none' : 'auto'"
            [ngStyle]="{
            'width': width,
            'background-color': contractExpiringSoon(element?.contract) && !element.statusPrestationLineIsWaiting ? 'rgba(255, 0, 0, 0.3)' :
                                element?.statusPrestationLineIsWaiting ? 'rgba(255, 245, 230, 1)' : null,
            'color': element?.contract?.status === 'EXPIRED' && !element.statusPrestationLineIsWaiting ? 'red' : null
        }"
            class='w-10 text-secondary' mat-cell>
            <ng-container
                    *ngTemplateOutlet="customColumnTemplate; context: {$implicit: column, element: element, status: status}"></ng-container>
        </td>
    </ng-container>
</ng-container>
