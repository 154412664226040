import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { fadeInUp400ms } from '../../../../../@vex/animations/fade-in-up.animation';
import { stagger40ms } from '../../../../../@vex/animations/stagger.animation';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { FeiColumn } from '../../interfaces/fei-column.interface';
import { animate, state, style, transition, trigger } from '@angular/animations';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icArchive from '@iconify/icons-ic/twotone-archive';
import { scaleIn400ms } from '../../../../../@vex/animations/scale-in.animation';
import { fadeInRight400ms } from '../../../../../@vex/animations/fade-in-right.animation';
import { scaleFadeIn400ms } from '../../../../../@vex/animations/scale-fade-in.animation';
import { dropdownAnimation } from '../../../../../@vex/animations/dropdown.animation';
import { popoverAnimation } from '../../../../../@vex/animations/popover.animation';
import { AuthService } from '../../../services/auth.service';
import { DEFAULT_PAGE_SIZE } from '../../../config/base-table.config';

@Component({
    selector: 'base-data-table',
    templateUrl: 'data-table-base.component.html',
    animations: [
        fadeInUp400ms,
        stagger40ms,
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        scaleIn400ms,
        fadeInRight400ms,
        scaleFadeIn400ms,
        scaleFadeIn400ms,
        dropdownAnimation,
        popoverAnimation
    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'standard'
            } as MatFormFieldDefaultOptions
        }
    ],
    styles: [
        `tr.example-detail-row {
            height: 0;
        }

        tr.example-element-row:not(.example-expanded-row):hover {
            background: whitesmoke;
        }

        tr.example-element-row:not(.example-expanded-row):active {
            background: #efefef;
        }

        .example-element-row td {
            border-bottom-width: 0;
        }

        .example-element-detail {
            overflow: hidden;
        }
        `
    ]
})

export class DataTableBaseComponent implements OnChanges, OnInit, AfterViewInit {

    expandedElement: any | null;
    icDelete = icDelete;
    icArchive = icArchive;
    selectedEl = [];
    clearSelectedEl = false;
    isLoading: boolean;

    @Input() dataSourceCatalog?: any;
    @Input() count: number;
    @Input() pageSize = DEFAULT_PAGE_SIZE;
    @Input() sortField: string;
    @Input() sortOrder: string;
    @Input() dataSource: MatTableDataSource<any>;
    @Input() displayedColumns: Array<string>;
    @Input() feisColumns: Array<FeiColumn>;
    @Input() clearSelectedRow = false;
    @Input() selectAllDisabled?: boolean;
    @Input() canSelectMultiple = true;
    @Input() pageSizeOptions = [10, 25, 50, 100];
    @Input() selectedRows: Array<any> = [];
    @Input() attentionRequiredTooltip = '';
    @Input() actionsTemplate: TemplateRef<any>;
    @Input() selectionActionTemplate: TemplateRef<any>;
    @Input() isOrderBasket = false;
    @Input() showExpired = false;
    @Input() showArchive = false;
    @Input() stickyTop = false;

    @Output() onPageEvent = new EventEmitter();
    @Output() onSortEvent = new EventEmitter();

    @Output() onSelectItem = new EventEmitter();
    @Output() onUpdateItem = new EventEmitter();
    @Output() onFavoriteItem = new EventEmitter();
    @Output() onDeleteItem = new EventEmitter();
    @Output() onDuplicateItem = new EventEmitter();
    @Output() onArchiveItem = new EventEmitter();
    @Output() onDownloadItem = new EventEmitter();
    @Output() onHoverItem = new EventEmitter();
    @Output() onSelectedRow = new EventEmitter();
    @Output() onChangeReadStatus = new EventEmitter();

    @Output() onRowClick = new EventEmitter();

    @Output() onValueChange = new EventEmitter();

    @Input() routerLinkDetailRow: string;

    @Input() showPaginator = true;
    @Input() showSort = true;

    @Input() accordeonRef: TemplateRef<any>;
    @Input() customColumnTemplate: TemplateRef<any>;
    @Input() customColumnHeaderTemplate: TemplateRef<any>;

    @Input() canSelectCheckBoxRows = true;

    @ViewChild('paginator') paginator: MatPaginator;

    @Output() onTabChangeEvent = new EventEmitter<any>();

    @Input() isExpiredOrderContractLine = false;
    @Input() actionText: string;
    @Input() isProductPrestation: boolean;

    constructor(
        private authService: AuthService
    ) {
    }

    ngOnInit() {
        this.isLoading = true;

        this.feisColumns = this.feisColumns.filter((column: FeiColumn) => {
            if (column.isPredik) {
                return this.authService.checkCurrentIsPredik();
            }
            return true;
        });

        this.displayedColumns = this.feisColumns.map((column: FeiColumn) => column.column);
    }

    ngAfterViewInit() {
        if(this.dataSource.filteredData) {
            this.isLoading = false;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.clearSelectedRow) {
            this.clearSelectedEl = this.clearSelectedRow;
        }
        if (changes?.count) {
            this.paginator?.firstPage();
        }
    }

    selectItem(event: any) {
        this.onSelectItem.emit(event);
    }

    updateItem(element) {
        this.onUpdateItem.emit(element);
    }

    duplicateItem(element) {
        this.onDuplicateItem.emit(element);
    }

    favoriteItem(element) {
        this.onFavoriteItem.emit(element);
    }

    changeReadStatus(element) {
        this.onChangeReadStatus.emit(element);
    }

    deleteItem(element) {
        this.onDeleteItem.emit(element);
    }

    downloadItem(element) {
        this.onDownloadItem.emit(element);
    }

    pageEvent($event: PageEvent) {
        this.onPageEvent.emit($event);
    }

    sortEvent($event: Sort) {
        this.onSortEvent.emit($event);
    }

    selectedRow($event) {
        this.selectedEl = $event;
        if (this.clearSelectedEl) {
            this.clearSelectedEl = false;
        }
        this.onSelectedRow.emit(this.selectedEl);
    }

    // rowRouter(element: any): Array<string> {
    //     const finalRoute = [];
    //     if (this.routerLinkDetailRow) {
    //         this.routerLinkDetailRow.split('/').ticket-filter(x => x).forEach(route => {
    //             if (route === ':id') {
    //                 finalRoute.push(element._id);
    //             } else {
    //                 finalRoute.push(route);
    //             }
    //         });
    //     }
    //
    //     [':id', 'toto', 'tata']
    //
    //     return finalRoute;
    // }

    rowClick(element) {
        this.onRowClick.emit(element);
    }

    valueChange(element: any) {
        this.onValueChange.emit(element);
    }

    archiveItem($event: any) {
        this.onArchiveItem.emit($event);
    }

    hoverItem(element) {
        this.onHoverItem.emit(element);
    }

    generateRowStyle(element: any): { [p: string]: any } | null {

        // if (this.showExpired && element.isExpired) {
        //    return {'background-color': 'rgba(255, 0, 0, 0.2)'};
        // } else if (element.isActive === false || this.showArchive && element.isArchive) {
        //     return {'background-color': '#f3f4f6'};
        // } else {
          return {
              'background-color': element.backgroundStyle ?? '',
              'color': element.textColorStyle ?? ''
          };
          // }
    }
}
