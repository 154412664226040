
export enum ApplicationEnvironmentEnum {
    PRODUCTION = 'production',
    DEV = 'dev',
    HOTFIX = 'hotfix',
    PREP = 'prep',
    STAGING = 'staging',
    QA = 'qa',
    FORMATION = 'formation',
    DEMO = 'demo',
}
